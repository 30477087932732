import React from 'react'
import { CheckOutlined ,CloseOutlined } from "@ant-design/icons";


function PricingContent(){
    return(
<div className="flex flex-col  items-center justify-center mb-4">
    <div className='my-2 text-lg font-semibold'>
        Plans and Pricing
    </div>
    <div className='flex text-3xl font-bold my-1'>
    Start for free and Upgrade anytime
    </div>
    <div className='flex text-3xl font-bold my-1'>
at just &nbsp; <span style={{fontSize:40 ,color:'green',fontWeight:800}}>$5 </span>
    </div>
    <div className='mt-2 mb-2 text-base'>Choose the best way to strengthen your hiring with resume parsing</div>
    {/* <div >
        <Button type="primary" style={{width:260}}>Start for FREE</Button>
    </div> */}
    <div className="flex text-base flex-row space-x-5 items-center justify-center">
          <div>
            <CheckOutlined style={{ color: "green" }} /> Free 50 resumes
          </div>
          <div>
            <CloseOutlined style={{ color: "red" }} /> No credit card required
          </div>
        </div>

</div>
    )
}
export default PricingContent;