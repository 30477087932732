import React from "react";
import MyTabs from "./TabPane";
import FAQCollapse from "./FAQuestion";
import PricingPage from "../pages/PricingPage";
function ConvertResumeSteps() {
  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-24">
        <div className="sm:text-4xl font-medium mb-4 text-xl">
          Convert Resumes into Powerful Data
        </div>
        <div className="sm:text-4xl font-medium mb-12 text-xl">in 3 Easy Steps</div>
        <div className="flex sm:flex-row justify-center mt-12 sm:space-x-32 flex-col items-center">
          <div className="flex flex-col mb-4 items-center">
            {/* <img src={""} alt="step1" /> */}
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="36 36.501 128 126.999"
              viewBox="36 36.501 128 126.999"
              height="100px"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
              data-type="shape"
              role="presentation"
              aria-hidden="true"
              aria-label=""
            >
              <g>
                <path
                  d="M164 119.843v26.459c0 9.483-7.775 17.198-17.333 17.198H53.333C43.775 163.5 36 155.785 36 146.302v-26.459c0-2.192 1.791-3.969 4-3.969s4 1.777 4 3.969v26.459c0 5.106 4.187 9.26 9.333 9.26h93.334c5.146 0 9.333-4.154 9.333-9.26v-26.459c0-2.192 1.791-3.969 4-3.969s4 1.777 4 3.969zM69.495 76.346 96 50.049v69.794c0 2.192 1.791 3.969 4 3.969s4-1.777 4-3.969V50.049l26.505 26.297c.78.775 1.805 1.163 2.828 1.163s2.048-.388 2.828-1.163a3.946 3.946 0 0 0 0-5.612l-33.33-33.071a4.016 4.016 0 0 0-.612-.497c-.099-.066-.207-.111-.31-.167-.125-.068-.245-.143-.378-.197-.134-.055-.273-.087-.41-.126-.112-.033-.22-.076-.336-.099a4.056 4.056 0 0 0-1.568 0c-.116.023-.223.066-.336.099-.138.04-.277.072-.411.127-.132.054-.252.129-.377.197-.104.056-.212.102-.311.168-.22.146-.426.312-.612.497L63.84 70.736a3.946 3.946 0 0 0 0 5.612 4.022 4.022 0 0 0 5.655-.002z"
                  fill="green"
                  height="3px"
                ></path>
              </g>
            </svg>
            <div className="text-base font-medium mt-8 mb-8">Step 1 </div>

            <div className="text-base font-medium">Upload your resumes </div>
            <div className="text-base font-medium">in bulk.</div>
          </div>
          <div className="flex flex-col mb-4 items-center">
            {/* <img src={""} alt="step1" /> */}
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="29.5 29.5 141 141"
              viewBox="29.5 29.5 141 141"
              height="100px"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
              data-type="color"
              role="presentation"
              aria-hidden="true"
              aria-label=""
            >
              <g>
                <path
                  d="M104 33.5v26.6a4 4 0 0 1-8 0V33.5a4 4 0 0 1 8 0zm-4 102.4a4 4 0 0 0-4 4v26.6a4 4 0 0 0 8 0v-26.6a4 4 0 0 0-4-4zM55.812 50.156a4 4 0 1 0-5.656 5.656l18.819 18.819c.78.781 1.805 1.172 2.828 1.172s2.048-.391 2.828-1.172a4 4 0 0 0 0-5.656L55.812 50.156zm75.212 75.212a4 4 0 1 0-5.656 5.656l18.819 18.819c.78.781 1.805 1.172 2.828 1.172s2.048-.391 2.828-1.172a4 4 0 0 0 0-5.656l-18.819-18.819zM64.1 100a4 4 0 0 0-4-4H33.5a4 4 0 0 0 0 8h26.6a4 4 0 0 0 4-4zm102.4-4h-26.6a4 4 0 0 0 0 8h26.6a4 4 0 0 0 0-8zm-97.524 29.368l-18.819 18.819a4 4 0 1 0 5.656 5.656l18.819-18.819a4 4 0 1 0-5.656-5.656zm59.22-49.564a3.987 3.987 0 0 0 2.828-1.172l18.819-18.819a4 4 0 1 0-5.656-5.656l-18.819 18.819a4 4 0 0 0 2.828 6.828z"
                  fill="green"
                  data-color="1"
                ></path>
              </g>
            </svg>
            <div className="text-base font-medium mt-8 mb-8">Step 2 </div>

            <div className="text-base font-medium">
              Let our parser work its{" "}
            </div>
            <div className="text-base font-medium">magic.</div>
          </div>
          <div className="flex flex-col mb-4 items-center">
            {/* <img src={""} alt="step1" />
             */}
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="36 36 128 128"
              viewBox="36 36 128 128"
              height="100px"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
              data-type="color"
              role="presentation"
              aria-hidden="true"
              aria-label=""
            >
              <g>
                <path
                  d="M164 120v26.667c0 9.558-7.775 17.333-17.333 17.333H53.333C43.775 164 36 156.225 36 146.667V120a4 4 0 0 1 8 0v26.667c0 5.146 4.187 9.333 9.333 9.333h93.334c5.146 0 9.333-4.187 9.333-9.333V120a4 4 0 0 1 8 0zm-66.831 2.826c.186.187.392.354.612.501.099.066.206.112.309.168.125.069.246.144.379.199.133.055.271.087.408.127.113.033.221.077.339.1.259.051.521.079.784.079s.525-.028.783-.079c.117-.023.226-.067.339-.1.137-.04.275-.072.408-.127.133-.055.254-.131.38-.2.103-.056.21-.102.308-.167.22-.147.426-.314.612-.501l33.33-33.331a4 4 0 1 0-5.656-5.656L104 110.343V40a4 4 0 0 0-8 0v70.343L69.495 83.839a4 4 0 1 0-5.656 5.656l33.33 33.331z"
                  fill="green"
                  data-color="1"
                ></path>
              </g>
            </svg>
            <div className="text-base font-medium mt-8 mb-8">Step 3</div>

            <div className="text-base font-medium">
              Download your organized{" "}
            </div>
            <div className="text-base font-medium">CSV.</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mt-24 mb-16 ">
        <div className="text-4xl font-medium mb-4">
          Automate resume parsing and
        </div>
        <div className="text-4xl font-medium">Focus on Top Talent</div>
      </div>
      <MyTabs />


      <div className="flex flex-col items-center justify-center mt-24 ">
        <div className="sm:text-4xl font-medium mb-4 text-xl">
          Boost your hiring process today!
        </div>
        <div className=" flex flex-col space-y-12 mt-12">
          <div className="flex flex-row justify-center space-x-12">
            <div className=" flex sm:text-3xl text-xl font-semibold sm:max-w-60 max-w-16 text-left">
              No learning curve needed
            </div>
            <div className="sm:max-w-xl text-left text-base max-w-40" >
              A user-friendly interface has been designed specifically for
              non-technical users, making it easy for anyone to navigate and
              use.
            </div>
          </div>

          <div className="flex flex-row justify-center space-x-12">
            <div className="flex sm:text-3xl text-xl font-semibold sm:max-w-60 max-w-16 text-left">
              Upload resumes in bulk to save time.
            </div>
            <div className="sm:max-w-xl text-left text-base max-w-40" >
              Drag and drop multiple resumes simultaneously, making handling
              large hiring campaigns or recruitment drives easier. This bulk
              upload feature eliminates manual entry, allowing you to focus on
              more strategic tasks.{" "}
            </div>
          </div>

          <div className="flex flex-row justify-center space-x-12">
            <div className="flex sm:text-3xl text-xl font-semibold sm:max-w-60 max-w-16 text-left">
              CSV files organized for analysis or HR system import.{" "}
            </div>
            <div className="sm:max-w-xl text-left text-base max-w-40" >
              Once parsed, your data is compiled into a clean, easy-to-read CSV
              file. This file is ready for immediate use, whether you need to
              analyze the data, import it into an HR system, or share it with
              other team members. The organized format ensures all information
              is easily accessible and usable.{" "}
            </div>
          </div>
        </div>
      </div>
<div className="pt-24" id="pricing-section">
      <PricingPage/>
      </div>

      <div className="flex flex-col items-center justify-center mt-24 mb-8">
        <div className="text-4xl font-medium mb-4">
          Frequently asked questions
        </div>
      </div>
      {/* <div className="max-w-80 sm:max-w-7xl items-center justify-center flex"> */}
      <FAQCollapse />
      {/* </div> */}

      
      <div className="flex flex-col items-center justify-center mt-16 border-t-1">
        <div className="mt-12 mb-2">
        {/* </div>
        <div className="text-left">
            By signing up, you agree to our&nbsp; */}
            <a
           
              // href="https://www.resumeparser.in/terms-of-use"
              href="/terms-of-use"
              style={{ color: "#009D79" }}
            >
              Terms of use{" "}&nbsp;
            </a>
            {/* &nbsp;and&nbsp; */}
            <a
              href="/privacy-policy"
              style={{ color: "#009D79" }}
            >
              {" "}
              Privacy policy &nbsp;
            </a>
            {/* &nbsp;Need help? Visit&nbsp; */}
            <a
              href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing"
              style={{ color: "#009D79" }}
            >
              Support
            </a>
          </div>
          <div className="mb-12">© 2024 by Resume Parser.</div>
      </div>
    </div>
  );
}
export default ConvertResumeSteps;
