import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { GetCheckoutSessions, GetPaymentPlans } from "../api";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

function capitalizeAllLetters(inputString:string) {
  if (typeof inputString !== 'string' || inputString.length === 0) {
      return '';
  }
  return inputString.toUpperCase();
}

type BillingPlanModalProps={
    isModalOpen: boolean,handleCancel:()=>void,
    // handlebillingConfirm:(value:any)=>any
}
const  BillingPlanModal=(props:BillingPlanModalProps) =>{
    const navigate=useNavigate()
    const [paymentPlans, setPaymentPlans] = useState<any>();
  const [isConvert, setIsCovert] = useState<boolean>(false);
  const {isModalOpen, handleCancel}=props
const user = useSelector((state: RootState) => state.user.user);


  useEffect(() => {
    const fetchPaymentPlans = async () => {
      try {
        const plans = await GetPaymentPlans();
        setPaymentPlans(plans);

      } catch (error) {
        console.error("Error fetching payment plans:", error);
      }
    };

    if (isModalOpen) {
      fetchPaymentPlans();
    }
  }, [isModalOpen]);

  
const amount=paymentPlans?.prices[0].unit_amount/100;
const amount_unit=capitalizeAllLetters(paymentPlans?.prices[0].currency);
const all_credits=paymentPlans?.product?.metadata?.credits;

    async function handlePaymentCall(event:any) {
        const checkOutdetails=await GetCheckoutSessions(`${process.env.REACT_APP_BILLING_BASE_URL}/conversion-wizard/inside`,user?.user_id);
        window.location.assign(checkOutdetails.url)
        if (checkOutdetails.payment_status==='done'){
            setIsCovert(true)
            navigate("/conversion-wizard/inside")
        }
        else{
            setIsCovert(false)
        }
    }
    // props.handlebillingConfirm(isConvert);
  return (
    <Modal
     open={isModalOpen} 
     onCancel={handleCancel}
     footer={null}
     closable
     width={'400px'}
    //  style={{width:'300px'}}
     >
      <div className="flex flex-col py-16 px-4" >
        <div className="flex text-xl font-bold items-center justify-center">
          Choose Your Plan
        </div>
        <div className="flex mt-6 text-left text-base font-medium" style={{ marginLeft:'4px'}}>
          Billing Plan
        </div>
        <div className="flex mt-2  mb-10 items-center justify-center">
          <div className=" flex flex-col w-80 h-20 p-2 space-y-2" style={{border:'1px solid #1890FF',borderLeft:'6px solid #1890FF'}}>
            <div className="flex flex-row justify-between">
                <div className="text-base font-semibold">Starter plan</div>
                <div className="text-sm font-semibold">{amount} {amount_unit} = {all_credits} credits</div>
            </div>
            <div>You will be charged {amount} {amount_unit} = {all_credits} credits</div>
          </div>
        </div>
        <div className="flex mt-3 items-center justify-center">
          <Button type="primary" className="w-80 h-10" onClick={handlePaymentCall}>Make Payment</Button>
        </div>
        <div className="flex flex-row mt-3 space-x-5 items-center justify-center">
          <div>
            <CheckOutlined style={{ color: "green" }} /> pay per resume
          </div>
          <div>
            <CheckOutlined style={{ color: "green" }} /> 5 free trial Resumes
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default BillingPlanModal
